import { useMediaQuery } from '@mui/material';
import Profile from './Profile';
import MobileSection from './MobileSection';

// ==============================|| HEADER - CONTENT ||============================== //
import styled from 'styled-components';
const DIV = styled.div`
    display: flex;
    justify-content: right;
    width: 100%;
`;

const HeaderContent = () => {
    const matchesXs = useMediaQuery((theme) => theme.breakpoints.down('md'));

    return (
        <DIV>
            {!matchesXs && <Profile />}
            {matchesXs && <MobileSection />}
        </DIV>
    );
};

export default HeaderContent;
