import { AuthProvider } from './contexts/auth-context';
import Routes from './routes';
import ThemeCustomization from 'themes';
import ScrollTop from 'components/ScrollTop';
import { NotificationProvider } from './contexts/notification-context';

const App = () =>{
    return (
        <AuthProvider>
            <NotificationProvider>
                <ThemeCustomization>
                    <ScrollTop>
                        <Routes />
                    </ScrollTop>
                </ThemeCustomization>
            </NotificationProvider>
        </AuthProvider>
    );
} 

export default App;
