import { LoginOutlined, ProfileOutlined, UserOutlined, GoldOutlined} from '@ant-design/icons';
const icons = {
    LoginOutlined,
    ProfileOutlined,
    UserOutlined,
    GoldOutlined
};

const pages = {
    id: 'authentication',
    title: 'Menu',
    type: 'group',
    children: [
        {
            id: 'users',
            title: 'Users',
            type: 'item',
            url: '/users',
            icon: icons.UserOutlined,
            target: false
        }
    ]
};

export default pages;
