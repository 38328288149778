import pages from './pages';

// ==============================|| MENU ITEMS ||============================== //

const menuItems = {
    //items: [dashboard, pages, utilities, support]
    items: [pages]
};

export default menuItems;
