import { Outlet, Navigate } from 'react-router-dom';
import { useAuth } from '../../contexts/auth-context';
import { useEffect, useRef } from 'react';
const MinimalLayout = () => {
    const { user } = useAuth();
    const isFirstRender = useRef(true);
    useEffect(() => {
        if (isFirstRender.current) {
            isFirstRender.current = false;
        }
    });

    if (!isFirstRender.current && user) {
        return <Navigate to='/users' />;
    }
    return (
        <>
            <Outlet />
        </>
    )
};

export default MinimalLayout;
