import Snackbar from '@mui/material/Snackbar';
import Alert from '@mui/material/Alert';

export interface NotificationProps {
  message: string;
  type: any;
  open: boolean;
  handleClose?: () => void;
}

export const Notification = ({
  message,
  type,
  open,
  handleClose,
}: NotificationProps): JSX.Element => {
  return (
    <Snackbar open={open} autoHideDuration={6000} onClose={handleClose}>
      <Alert elevation={6} variant="filled" severity={type} onClose={handleClose} >
        {message}
      </Alert>
    </Snackbar>
  );
};
