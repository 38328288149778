import { createContext, FC, useContext, useEffect, useState } from 'react';
import { deleteToken, getToken, setToken } from '../helpers/auth-helper';
import { LoggedUser } from '../types/LoggedUser';

export type AuthContextType = {
    user?: LoggedUser | null;
    onLogin: (data: LoggedUser) => void;
    onLogout: () => void;
    getFullUserName: () => string;
  };
  
  const AuthContext = createContext<AuthContextType | Record<string, never>>({});
  
  export const AuthProvider: FC = (props) => {
    const { children } = props;
    const [user, setUser] = useState<LoggedUser | null | undefined>();
    
    // handle a successful login
    const onLogin = (user: LoggedUser) => {
      setUser(user);
      setToken(user);
    };
  
    // handle a successful logout
    const onLogout = () => {
      deleteToken();
      setUser(null);
    };
    const getFullUserName = () => `${user?.firstName} ${user?.lastName}`;
      
    const authContextValue: AuthContextType = {
      user,
      onLogin,
      onLogout,
      getFullUserName
    };
  
    // get token from localstorage and set a current user if a token exists
    useEffect(() => {
      const token = getToken();
      if (!token) {
        setUser(null);
        return;
      } else {
        setUser(token);
      }
    }, []);
  
    return (
      <AuthContext.Provider value={authContextValue}>
        {children}
      </AuthContext.Provider>
    );
  };
  
  export const useAuth = (): AuthContextType | Record<string, never> => {
    return useContext(AuthContext);
  };
  