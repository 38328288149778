import jwtDecode, { JwtPayload } from 'jwt-decode';
import { LoggedUser } from '../types/LoggedUser';

const TOKEN_KEY = 'currentUser';

export function setToken(token: LoggedUser): void {
  localStorage.setItem(TOKEN_KEY, JSON.stringify(token));
}

export function getToken(): LoggedUser | null {
  const token = localStorage.getItem(TOKEN_KEY);
  if (!token) return null;
  return JSON.parse(token);
}

export function deleteToken(): void {
  localStorage.removeItem(TOKEN_KEY);
}

export const isTokenExpired = (token: string): boolean => {
  const expirationTime = jwtDecode<JwtPayload>(token).exp;
  if (!expirationTime) return false;
  return expirationTime < Date.now() / 1000;
};
