import { lazy } from 'react';
import Loadable from 'components/Loadable';
import MainLayout from 'layout/MainLayout';
import { Navigate } from 'react-router';

const UsersPage = Loadable(lazy(() => import('pages/users/User')));
const UserProgressPage = Loadable(lazy(() => import('pages/users/user-progress/UserProgress')));

const MainRoutes = {
    path: '/', 
    element: <MainLayout />,
    children: [
        { path: '/', element: <Navigate to="/users" /> },
        { path: 'users', caseSensitive: true, element: <UsersPage /> },
        { path: 'users-progress/:idUser', caseSensitive: true, element: <UserProgressPage /> },
        { path: '*',  element: <Navigate to="/users" /> },
    ]
};

export default MainRoutes;
